import { render, staticRenderFns } from "./Copyright.vue?vue&type=template&id=43e0ccf1&scoped=true&"
var script = {}
import style0 from "./Copyright.vue?vue&type=style&index=0&id=43e0ccf1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e0ccf1",
  null
  
)

export default component.exports